import "$styles/index.css"
import "$styles/syntax-highlighting.css"
/* import * as Turbo from "@hotwired/turbo" */

/* import "./turbo_transitions.js"   */
/**
 * Adds support for declarative shadow DOM. Requires your HTML <head> to include:
 * `<meta name="turbo-cache-control" content="no-cache" />`
 */
/* import * as TurboShadow from "turbo-shadow" */

/**
 * Uncomment the line below to add transition animations when Turbo navigates.
 * Use data-turbo-transition="false" on your <main> element for pages where
 * you don't want any transition animation.
 */

import "bridgetown-quick-search/dist"
// import "./turbo_transitions.js"
import "./navbar.js"

/*
window.addEventListener("turbo:load", () => {
	document.querySelectorAll("sl-button").forEach(button => {
		button.addEventListener("click", e => {
			e.currentTarget.closest('.modal').querySelector('sl-dialog').show()
			if (e.target.localName != "a") {
				const anchor = e.currentTarget.querySelector("a")
				if (anchor) anchor.click()
			}
		})
	})
})
*/
// Example Shoelace components. Mix 'n' match however you like!
import "@shoelace-style/shoelace/dist/components/button/button.js"
import "@shoelace-style/shoelace/dist/components/icon/icon.js"
//import "@shoelace-style/shoelace/dist/components/spinner/spinner.js"
import '@shoelace-style/shoelace/dist/components/alert/alert.js'
import '@shoelace-style/shoelace/dist/components/avatar/avatar.js'
import '@shoelace-style/shoelace/dist/components/card/card.js'
//import '@shoelace-style/shoelace/dist/components/input/input.js'
//import '@shoelace-style/shoelace/dist/components/dialog/dialog.js'
import '@shoelace-style/shoelace/dist/components/tag/tag.js'

import '@shoelace-style/shoelace/dist/components/animation/animation.js';
//import  register_icon_library  from '@shoelace-style/shoelace/dist/utilities/icon-library.js'
//import  { set_animation } from '@shoelace-style/shoelace/dist/utilities/animation-registry.js'




// Use the public icons folder:
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path.js"
setBasePath("/shoelace-assets")

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"


//  VEGA
//import embed from "vega-embed"
//window.vegaEmbed = embed
//window.dispatchEvent(new Event("vega:load"))


console.info("Bridgetown is loaded!")
